mat-chip,
mat-chip-row,
mat-chip-option {
  &.custom {
    --mdc-chip-label-text-line-height: var(--line-height-143);
    --mdc-chip-label-text-size: var(--font-size-14);
    --mdc-chip-label-text-weight: normal;

    --mdc-chip-container-shape-family: rounded;
    --mdc-chip-container-shape-radius: var(--border-radius-xs);
    --mdc-chip-with-icon-icon-size: var(--font-size-16);

    &.mat-mdc-standard-chip {
      --mdc-chip-container-height: 28px;

      .mdc-evolution-chip__icon--trailing {
        height: 16px;
        width: 16px;
        font-size: var(--font-size-16);
      }

      &.multi-select-chip {
        .mat-mdc-chip-action-label {
          overflow: hidden;
          max-width: 210px;
        }
      }

      &.sm {
        --mdc-chip-container-height: 24px;
      }
    }

    .mat-mdc-chip-remove {
      opacity: 1;
    }

    &.primary {
      --mdc-chip-elevated-container-color: var(--UI-DS-Brand-500);
      --mdc-chip-label-text-color: var(--UI-DS-Base-White);
      --mdc-chip-with-icon-icon-color: var(--UI-DS-Base-White);
      --mdc-chip-with-trailing-icon-trailing-icon-color: var(
        --UI-DS-Base-White
      );
    }

    &.selected {
      --mdc-chip-elevated-container-color: var(--UI-DS-Brand-500) !important;
      --mdc-chip-label-text-color: var(--UI-DS-Base-White) !important;
      --mdc-chip-with-icon-icon-color: var(--UI-DS-Base-White);
      --mdc-chip-with-trailing-icon-trailing-icon-color: var(
        --UI-DS-Base-White
      );
    }

    &.secondary {
      border: var(--border-1-brand-500);
      --mdc-chip-elevated-container-color: var(--UI-DS-Base-White);
      --mdc-chip-label-text-color: var(--UI-DS-Brand-500);
      --mdc-chip-with-icon-icon-color: var(--UI-DS-Brand-500);
      --mdc-chip-with-trailing-icon-trailing-icon-color: var(--UI-DS-Brand-500);
    }

    &.rounded {
      --mdc-chip-container-shape-radius: var(--border-radius-xl) !important;
    }

    &.badge {
      .mat-mdc-chip-action-label {
        display: flex;
        align-items: center;
        gap: var(--dim-xxs);
      }

      .mdc-evolution-chip__action--primary {
        cursor: initial;
      }

      .mdc-evolution-chip__action--trailing {
        color: var(--UI-DS-Brand-500);
      }

      .mdc-evolution-chip__icon--trailing {
        height: 14px;
        width: 14px;
        font-size: var(--font-size-14);
      }
    }

    &.multi-select-chip {
      border: var(--border-1-gray-300);
      --mdc-chip-container-shape-radius: var(--border-radius-6);
      --mdc-chip-elevated-container-color: var(--UI-DS-Base-White);
      --mdc-chip-label-text-color: var(--UI-DS-Gray-800);
      --mdc-chip-with-icon-icon-color: var(--UI-DS-Gray-800);
      --mdc-chip-with-trailing-icon-trailing-icon-color: var(--UI-DS-Gray-400);

      &.sm .mdc-evolution-chip__icon--trailing {
        height: 10px;
        width: 10px;
        font-size: var(--font-size-10);
      }
    }
  }
}

mat-chip-option {
  &.custom {
    &.mat-mdc-standard-chip {
      --mdc-chip-elevated-container-color: var(--UI-DS-Base-White);
      --mdc-chip-outline-width: var(--dim-pixel);
      --mdc-chip-outline-color: var(--UI-DS-Brand-500);
      --mdc-chip-label-text-color: var(--UI-DS-Brand-500);

      .mdc-evolution-chip__text-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: var(--dim-xs);
      }

      &:not(.selected).mdc-evolution-chip--selected:not(
          .mdc-evolution-chip--disabled
        ) {
        --mdc-chip-elevated-selected-container-color: var(--UI-DS-Base-White);
        --mdc-chip-selected-label-text-color: var(--UI-DS-Brand-500);
        border: var(--border-1-brand-500);
      }
    }
  }
}
