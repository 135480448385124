ngx-file-drop {
  &.custom-file-drop {
    width: 100%;

    :hover {
      cursor: pointer;
    }

    .ngx-file-drop__drop-zone {
      height: auto;
      padding: var(--dim-mds) var(--dim-md);
      border-radius: var(--border-radius-ssm);
      border: var(--border-1-gray-300);
    }

    .ngx-file-drop__content {
      height: auto;
    }
  }
}
