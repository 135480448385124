mat-datepicker-content {
  --mat-datepicker-calendar-date-disabled-state-text-color: var(
    --UI-DS-Gray-300
  );
  --mat-datepicker-calendar-date-selected-state-background-color: var(
    --UI-DS-Brand-500
  );
  --mat-datepicker-calendar-date-selected-state-text-color: var(
    --UI-DS-Base-White
  );
  --mat-datepicker-calendar-container-elevation-shadow: var(--shadow-lg);
  --mat-datepicker-calendar-container-shape: var(--border-radius-sm);
  --mat-datepicker-calendar-text-size: var(--font-size-14);
  --mat-datepicker-calendar-date-today-outline-color: var(--UI-DS-Gray-300);
  --mat-datepicker-calendar-date-hover-state-background-color: var(
    --UI-DS-Gray-100
  );
  --mat-datepicker-calendar-date-focus-state-background-color: var(
    --UI-DS-Gray-200
  );

  border: var(--border-1-gray-300);

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    color: var(--UI-DS-Base-Black);
  }

  .cdk-keyboard-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ),
  .cdk-program-focused
    .mat-calendar-body-active
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    color: var(--UI-DS-Base-Black);
  }

  .mat-datepicker-actions {
    padding: var(--dim-sm);
    border-top: var(--border-1-gray-300);
  }

  .mat-calendar-content {
    padding: 0 var(--dim-md) var(--dim-mds);
  }

  .mat-calendar-body-label {
    font-size: 0;
    &:last-child {
      display: none;
    }
  }

  .mat-calendar-table-header-divider::after {
    display: none;
  }

  mat-calendar-header.without-controls {
    .mat-calendar-controls {
      width: max-content;

      .mat-calendar-previous-button,
      .mat-calendar-next-button {
        display: none;
      }
    }
  }
}

mat-timepicker-content {
  &.mat-timepicker-content {
    --mat-timepicker-content-layout-title-font-size: var(--font-size-16);
    --mat-timepicker-content-layout-title-font-weight: var(--font-weight-500);
    --mat-timepicker-content-layout-title-color: var(--UI-DS-Gray-90);
    --mat-timepicker-time-inputs-field-font-size: var(--font-size-36);
    --mat-timepicker-time-inputs-field-line-height: var(--line-height-44);
    --mat-timepicker-time-inputs-field-background-color: var(
      --UI-DS-Base-White
    );
    --mat-timepicker-content-border-radius: var(--border-radius-sm);
    border: var(--border-1-gray-300);
    box-shadow: var(--shadow-lg);

    .mat-timepicker-content-container {
      padding: 0;
    }

    .mat-time-inputs {
      padding: var(--dim-sm);
    }

    .mat-time-toggle-mode-button {
      display: none;
    }

    .mat-timepicker-content-actions {
      border-top: var(--border-1-gray-300);
      justify-content: flex-end;
      margin-right: 0;
      margin-top: 0;
      padding: var(--dim-sm);
    }

    .mat-timepicker-content-layout-title {
      margin-bottom: var(--dim-sm);
      letter-spacing: normal;
    }

    .mat-time-inputs-field.mat-form-field-appearance-outline
      .mat-mdc-form-field-subscript-wrapper {
      font: var(--body-sm-regular);
      color: var(--UI-DS-Gray-600);
      letter-spacing: normal;
    }

    .mat-timepicker-content-layout-hours,
    .mat-timepicker-content-layout-minutes {
      .mat-mdc-text-field-wrapper {
        border: var(--border-1-gray-300);
        padding: var(--dim-xs) var(--dim-sm);
        border-radius: var(--border-radius-xs);
      }
    }
  }
}
