mat-slider {
  --slider-handle-size: 24px;
  --mdc-slider-handle-height: var(--slider-handle-size);
  --mdc-slider-handle-width: var(--slider-handle-size);
  --mat-slider-value-indicator-height: var(--slider-handle-size);
  --mdc-slider-inactive-track-height: 8px;

  --mdc-slider-active-track-color: var(--UI-DS-Brand-500);
  --mdc-slider-inactive-track-color: var(--UI-DS-Gray-200);

  &.mat-mdc-slider .mdc-slider__thumb-knob {
    background-color: var(--UI-DS-Base-White);
    border: var(--border-2-brand-600);
    box-shadow: var(--box-shadow-slider);
  }

  &.mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__thumb-knob {
    background-color: var(--UI-DS-Base-White);
    border: var(--border-2-brand-600);
    box-shadow: var(--box-shadow-slider);
  }
}
