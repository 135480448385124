mat-slide-toggle {
  &.mat-mdc-slide-toggle {
    --mdc-switch-selected-handle-color: var(--UI-DS-Base-White);
    --mdc-switch-selected-focus-handle-color: var(--UI-DS-Base-White);
    --mdc-switch-selected-hover-handle-color: var(--UI-DS-Base-White);
    --mdc-switch-selected-pressed-handle-color: var(--UI-DS-Base-White);
    --mdc-switch-selected-focus-track-color: var(--UI-DS-Brand-600);
    --mdc-switch-selected-hover-track-color: var(--UI-DS-Brand-600);
    --mdc-switch-selected-pressed-track-color: var(--UI-DS-Brand-600);
    --mdc-switch-selected-track-color: var(--UI-DS-Brand-600);
    --mdc-switch-unselected-focus-handle-color: var(--UI-DS-Base-White);
    --mdc-switch-unselected-focus-track-color: var(--UI-DS-Gray-200);
    --mdc-switch-unselected-handle-color: var(--UI-DS-Base-White);
    --mdc-switch-unselected-hover-handle-color: var(--UI-DS-Base-White);
    --mdc-switch-unselected-hover-track-color: var(--UI-DS-Gray-200);
    --mdc-switch-unselected-pressed-handle-color: var(--UI-DS-Base-White);
    --mdc-switch-unselected-pressed-track-color: var(--UI-DS-Gray-100);
    --mdc-switch-unselected-track-color: var(--UI-DS-Gray-100);
  }

  .mdc-switch {
    --mdc-switch-handle-height: 14px;
    --mdc-switch-handle-shape: 10px;
    --mdc-switch-handle-width: 14px;
    --mdc-switch-selected-icon-size: 18px;
    --mat-switch-with-icon-handle-size: 16px;
    --mdc-switch-track-height: 20px;
    --mdc-switch-track-shape: 12px;
    --mdc-switch-track-width: 36px;
    --mdc-switch-state-layer-size: 30px;

    &.mdc-switch--selected {
      .mdc-switch__handle-track {
        left: -4px;
      }
    }

    &.mdc-switch--unselected {
      .mdc-switch__handle-track {
        left: 3px;
      }
    }

    .mdc-switch__icons {
      display: none;
    }
  }
}

mat-button-toggle-group {
  --mat-standard-button-toggle-shape: var(--border-radius-sm);
  --mat-standard-button-toggle-label-text-font: var(--font-family-sora);
  --mat-standard-button-toggle-label-text-size: normal;
  --mat-standard-button-toggle-label-text-line-height: var(--line-height-143);
  --mat-standard-button-toggle-label-text-weight: var(--font-weight-600);
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-selected-state-text-color: var(--UI-DS-Gray-800);
  --mat-standard-button-toggle-text-color: var(--UI-DS-Gray-700);

  &.icons {
    --mat-standard-button-toggle-selected-state-background-color: var(
      --UI-DS-Gray-200
    );
    border-radius: var(--border-radius-ssm);
    height: 40px;
    align-items: center;
    .mat-button-toggle-label-content {
      padding: var(--dim-10) var(--dim-ssm);
      mat-icon {
        height: 20px;
        width: 20px;
      }
    }
  }

  &.light {
    border-radius: var(--border-radius-xs);
    width: fit-content;
    --mat-standard-button-toggle-label-text-size: var(--font-size-14);
    --mat-standard-button-toggle-label-text-line-height: 20px;
    --mat-standard-button-toggle-height: 40px;
    --mat-standard-button-toggle-selected-state-background-color: var(
      --UI-DS-Gray-50
    );
  }
}
