button {
  --standard-size-button: 40px;
  min-height: var(--standard-size-button);

  //mat-flat-button
  --mdc-filled-button-label-text-weight: var(--font-weight-600);
  --mdc-filled-button-label-text-size: var(--font-size-16);
  --mdc-filled-button-label-text-tracking: var(--letter-spacing-064);
  --mdc-filled-button-disabled-container-color: var(--UI-DS-Gray-200);
  --mdc-filled-button-disabled-label-text-color: var(--UI-DS-Base-White);

  //mat-raised-button
  --mdc-protected-button-container-height: var(--standard-size-button);
  --mdc-protected-button-label-text-size: var(--font-size-16);
  --mdc-protected-button-label-text-tracking: var(--letter-spacing-064);
  --mdc-protected-button-label-text-weight: var(--font-weight-600);
  --mat-protected-button-horizontal-padding: 12px;
  --mat-protected-button-vertical-padding: 24px;

  --mdc-protected-button-disabled-label-text-color: var(--UI-DS-Base-White);
  --mdc-protected-button-disabled-container-color: var(--UI-DS-Brand-200);
  --mdc-protected-button-container-shape: 12px;

  //mat-stroked-button
  --mdc-outlined-button-container-height: var(--standard-size-button);
  --mdc-outlined-button-outline-color: var(--UI-DS-Gray-300);
  --mdc-outlined-button-label-text-color: var(--UI-DS-Gray-700);
  --mdc-outlined-button-label-text-weight: var(--font-weight-600);
  --mdc-outlined-button-disabled-label-text-color: var(--UI-DS-Gray-300);
  --mdc-outlined-button-disabled-outline-color: var(--UI-DS-Gray-300);
  --mdc-outlined-button-disabled-label-text-color: var(--UI-DS-Gray-400);
  --mdc-outlined-button-container-shape: 12px;
  --mdc-outlined-button-label-text-tracking: var(--letter-spacing-064);

  &.mat-mdc-raised-button {
    --mdc-protected-button-hover-container-elevation-shadow: none;
    --mdc-protected-button-container-elevation-shadow: none;
    --mdc-protected-button-focus-container-elevation-shadow: var(
      --shadow-brand-outline-400
    );
    &:not(:disabled) {
      --mdc-protected-button-focus-container-elevation-shadow: none;
      &:hover {
        --mdc-protected-button-container-color: var(--UI-DS-Brand-700);
      }

      &:focus {
        box-shadow: var(--shadow-brand-outline-400);
      }
    }
  }

  &.mat-mdc-outlined-button {
    &:not(:disabled):focus {
      box-shadow: var(--shadow-brand-outline-400);
    }
  }
  &.time-modal {
    margin-right: var(--dim-xs);
  }

  &.mat-calendar-body-cell {
    --standard-size-button: 35px;
  }

  &.small {
    --standard-size-button: 32px;
    min-height: var(--standard-size-button);
    //mat-raised-button
    --mdc-protected-button-container-height: var(--standard-size-button);
    --mdc-protected-button-label-text-size: var(--font-size-12);
    --mdc-protected-button-label-text-tracking: var(--letter-spacing-056);
    --mdc-protected-button-label-text-weight: var(--font-weight-500);
    --mdc-protected-button-container-shape: 8px;

    //mat-stroked-button
    --mdc-outlined-button-container-height: var(--standard-size-button);
    --mdc-outlined-button-label-text-size: var(--font-size-12);
    --mdc-outlined-button-label-text-color: var(--UI-DS-Gray-700);
    --mdc-outlined-button-container-shape: 8px;

    &.mat-mdc-icon-button {
      --mdc-icon-button-state-layer-size: var(--standard-size-button);
      --mdc-icon-button-icon-size: 16px;
      padding: calc(
        calc(
            var(--mdc-icon-button-state-layer-size, 48px) - var(
                --mdc-icon-button-icon-size,
                24px
              )
          ) / 2
      );

      mat-icon {
        width: var(--mdc-icon-button-icon-size);
        height: var(--mdc-icon-button-icon-size);
        font-size: var(--mdc-icon-button-icon-size);
      }
    }
  }

  &.medium {
    --standard-size-button: 40px;
    min-height: var(--standard-size-button);
    //mat-raised-button
    --mdc-protected-button-container-height: var(--standard-size-button);
    --mdc-protected-button-label-text-size: var(--font-size-14);
    --mdc-protected-button-label-text-tracking: var(--letter-spacing-056);
    --mdc-protected-button-container-shape: 10px;
    --mdc-protected-button-label-text-weight: var(--font-weight-400);
    --mat-protected-button-horizontal-padding: 16px;

    //mat-stroked-button
    --mdc-outlined-button-container-height: var(--standard-size-button);
    --mdc-outlined-button-container-shape: 10px;

    &.mat-mdc-icon-button {
      --mdc-icon-button-state-layer-size: var(--standard-size-button);
      --mdc-icon-button-icon-size: 20px;
      padding: calc(
        calc(
            var(--mdc-icon-button-state-layer-size, 48px) - var(
                --mdc-icon-button-icon-size,
                24px
              )
          ) / 2
      );

      mat-icon {
        width: var(--mdc-icon-button-icon-size);
        height: var(--mdc-icon-button-icon-size);
        font-size: var(--mdc-icon-button-icon-size);
      }
    }
  }

  &.large {
    --standard-size-button: 48px;
    min-height: var(--standard-size-button);
    //mat-raised-button
    --mdc-protected-button-container-height: var(--standard-size-button);
    --mdc-protected-button-container-shape: 12px;
    --mat-protected-button-horizontal-padding: var(--dim-md);
    --mdc-protected-button-label-text-size: var(--font-size-16);
    --mdc-protected-button-label-text-weight: var(--font-weight-500);

    //mat-stroked-button
    --mdc-outlined-button-label-text-size: var(--font-size-16);
  }

  &.providers-button {
    --standard-size-button: 48px;
    padding: 16px 24px;

    &.mat-mdc-outlined-button {
      --mdc-outlined-button-container-shape: 12px;
      --mdc-outlined-button-label-text-size: var(--font-size-14);
      --mdc-outlined-button-label-text-weight: var(--font-weight-500);
      --mdc-outlined-button-label-text-color: var(--UI-DS-Gray-800);

      & > .mat-icon {
        height: 24px;
        width: 24px;
      }
    }
  }

  &.icon-button {
    min-width: 40px;
    &.mat-mdc-outlined-button {
      --mdc-outlined-button-container-shape: 12px;
      padding: 0;
      & > .mat-icon {
        margin: unset;
      }
    }

    &.small {
      min-width: 32px;
      &.mat-mdc-outlined-button {
        --mdc-outlined-button-container-shape: 8px;
        padding: 0;
        & > .mat-icon {
          margin: unset;
          --icon-size: var(--font-size-16);
          font-size: var(--icon-size);
          height: var(--icon-size);
          width: var(--icon-size);
        }
      }
    }

    &.primary {
      background-color: var(--UI-DS-Brand-500);
    }

    &.secondary-small {
      --mdc-text-button-label-text-color: var(--UI-DS-Brand-500);
      --mdc-text-button-label-text-tracking: 0;
      --standard-size-button: 28px;
    }

    &.rounded {
      --mdc-outlined-button-container-shape: 50%;
    }

    .icon {
      margin: 0;
    }
  }

  &.full-width {
    width: 100%;
  }

  &.mat-primary {
    --mdc-filled-button-label-text-color: var(--UI-DS-Base-White);
    --mdc-protected-button-label-text-color: var(--UI-DS-Base-White) !important;
    --mat-protected-button-state-layer-color: #000;

    &.destructive {
      --mdc-protected-button-container-color: var(--UI-DS-Semantic-Error-500);
      --mdc-protected-button-label-text-color: var(--UI-DS-Base-White);
      --mdc-protected-button-label-text-weight: var(--font-weight-500);
      --mdc-protected-button-focus-container-elevation-shadow: none;

      &:hover {
        --mdc-protected-button-container-color: var(--UI-DS-Semantic-Error-600);
      }

      &:disabled {
        --mdc-protected-button-container-color: var(--UI-DS-Semantic-Error-200);
      }
    }
  }

  &.link {
    --mdc-text-button-label-text-font: var(--link-xs);
    --mdc-text-button-label-text-size: var(--font-size-14);
    --mdc-text-button-label-text-tracking: var(--letter-spacing-028);
    --mdc-text-button-label-text-weight: var(--font-weight-600);
    --mdc-text-button-container-height: var(--font-size-14);
    --mdc-text-button-label-text-color: var(--UI-DS-Brand-600);
    --mdc-text-button-disabled-label-text-color: var(--UI-DS-Brand-200);
    --mat-text-button-horizontal-padding: 0px;
    --mat-text-button-hover-state-layer-opacity: 0;

    min-height: var(--mdc-text-button-container-height);

    .mat-mdc-button-touch-target {
      height: var(--mdc-text-button-container-height);
    }

    &:hover {
      --mdc-text-button-label-text-color: var(--UI-DS-Brand-700);
    }

    &:disabled {
      --mdc-text-button-label-text-color: var(--UI-DS-Brand-200);
    }

    &.medium {
      --mdc-text-button-label-text-weight: var(--font-weight-500);
      --mdc-text-button-container-height: 20px;
    }

    &.gray {
      --mdc-text-button-label-text-color: var(--UI-DS-Gray-700);
      --mdc-text-button-disabled-label-text-color: var(--UI-DS-Gray-300);

      &:hover {
        --mdc-text-button-label-text-color: var(--UI-DS-Gray-800);
      }
    }
  }

  &.button-text-icon {
    .mat-icon {
      height: 2.5rem;
    }
  }

  &.icon-small {
    transform: scale(0.65);
  }

  &.spinner {
    mat-spinner.button {
      --spinner-size: 24px;
      width: var(--spinner-size) !important;
      height: var(--spinner-size) !important;
      --mdc-circular-progress-size: var(--spinner-size) !important;
      --mdc-circular-progress-active-indicator-width: var(
        --spinner-size
      ) !important;
    }
  }

  &.force-flex {
    .mdc-button__label {
      display: flex;
      gap: var(--dim-xxs);
    }
  }

  &.close-modal {
    margin-top: calc(-1 * var(--dim-sm));
    margin-right: calc(-1 * var(--dim-sm));
  }
}
