mat-checkbox {
  width: 100%;

  &.fake-disabled {
    --mat-checkbox-disabled-label-color: black;
    .mdc-checkbox {
      --mdc-checkbox-disabled-selected-icon-color: var(--UI-DS-Base-White);
      --mdc-checkbox-disabled-selected-checkmark-color: var(--UI-DS-Brand-600);
    }
  }

  .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    border-color: var(--UI-DS-Brand-600) !important;
  }

  &:not(.multi-select):not(.small) {
    .mdc-checkbox__native-control:checked
      ~ .mdc-checkbox__background
      .mdc-checkbox__checkmark {
      width: 11px;
      height: 12px;
      top: 1px;
      left: 1px;
    }
  }

  .mdc-checkbox__background {
    border-radius: var(--border-radius-xxs);
    color: var(--UI-DS-Brand-600);
  }

  .mdc-checkbox {
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-selected-checkmark-color: var(--UI-DS-Base-White);
    --mdc-checkbox-selected-focus-icon-color: var(--UI-DS-Brand-600);
    --mdc-checkbox-selected-hover-icon-color: var(--UI-DS-Brand-600);
    --mdc-checkbox-selected-icon-color: var(--UI-DS-Brand-600);
    --mdc-checkbox-selected-pressed-icon-color: var(--UI-DS-Brand-600);
    --mdc-checkbox-unselected-focus-icon-color: var(--UI-DS-Gray-300);
    --mdc-checkbox-unselected-hover-icon-color: var(--UI-DS-Gray-300);
    --mdc-checkbox-unselected-icon-color: var(--UI-DS-Gray-300);
    --mdc-checkbox-unselected-pressed-icon-color: var(--UI-DS-Gray-300);
    --mdc-checkbox-selected-focus-state-layer-color: var(--UI-DS-Base-White);
    --mdc-checkbox-selected-hover-state-layer-color: var(--UI-DS-Base-White);
    --mdc-checkbox-selected-pressed-state-layer-color: var(--UI-DS-Base-White);
    --mdc-checkbox-unselected-focus-state-layer-color: black;
    --mdc-checkbox-unselected-hover-state-layer-color: black;
    --mdc-checkbox-unselected-pressed-state-layer-color: black;
  }

  &.small {
    --mdc-checkbox-state-layer-size: 20px;
    .mdc-checkbox {
      transform: scale(0.8);

      .mdc-checkbox__checkmark {
        transform: scale(0.7);
      }
    }
  }

  &.multi-select {
    .mat-internal-form-field {
      display: flex;
      flex-direction: row-reverse;
    }

    .mdc-checkbox {
      --mdc-checkbox-selected-checkmark-color: var(--UI-DS-Brand-600);
      --mdc-checkbox-selected-hover-checkmark-color: var(--UI-DS-Brand-600);
      --mdc-checkbox-selected-hover-icon-color: var(--UI-DS-Base-White);
      --mdc-checkbox-selected-icon-color: var(--UI-DS-Base-White);
      --mdc-checkbox-unselected-focus-icon-color: transparent;
      --mdc-checkbox-unselected-hover-icon-color: transparent;
      --mdc-checkbox-unselected-icon-color: transparent;
      --mdc-checkbox-unselected-pressed-icon-color: transparent;
      --mdc-checkbox-selected-focus-state-layer-color: transparent;
      --mdc-checkbox-selected-hover-state-layer-color: transparent;
      --mdc-checkbox-selected-pressed-state-layer-color: transparent;
      --mdc-checkbox-unselected-focus-state-layer-color: transparent;
      --mdc-checkbox-unselected-hover-state-layer-color: transparent;
      --mdc-checkbox-unselected-pressed-state-layer-color: transparent;

      .mdc-checkbox__background {
        border-color: transparent;
        background-color: transparent;
      }

      .mdc-checkbox__native-control:enabled:checked
        ~ .mdc-checkbox__background {
        background-color: transparent;
        border-color: transparent !important;
      }
    }
  }

  &.fake-disabled {
    --mdc-checkbox-disabled-selected-icon-color: var(--UI-DS-Base-White);
    .mdc-checkbox__native-control:disabled:checked ~ .mdc-checkbox__background {
      border-color: var(--UI-DS-Brand-600) !important;
    }
  }

  .mdc-form-field {
    width: 100%;
  }

  .mdc-label {
    width: 100%;
    padding: calc((var(--mdc-checkbox-state-layer-size) - 18px) / 2);
    overflow: hidden;
    text-overflow: ellipsis;

    span.value-label {
      font: var(--body-sm-semibold);
    }
  }
}
