.dmc-console-dialog {
  --mdc-dialog-container-shape: var(--dim-ssm);
  --mat-dialog-headline-padding: var(--dim-md) var(--dim-md);
  --mat-dialog-content-padding: 0 var(--dim-md) var(--dim-md) var(--dim-md);
  --mat-dialog-with-actions-content-padding: 0 var(--dim-md) var(--dim-md)
    var(--dim-md);
  --mat-dialog-actions-padding: 0px var(--dim-md) var(--dim-md) var(--dim-md);
  --mat-dialog-actions-alignment: end;
  --mdc-dialog-subhead-line-height: var(--font-size-18);

  mat-dialog-container {
    .mdc-dialog__title::before {
      display: none;
    }
  }

  mat-dialog-actions {
    .mat-button-base + .mat-button-base,
    .mat-mdc-button-base + .mat-mdc-button-base {
      margin-left: var(--dim-xs);
    }
  }

  .mat-mdc-dialog-surface {
    --mat-dialog-container-elevation-shadow: var(--shadow-xl);
    border: var(--border-1-gray-200);
  }
}
