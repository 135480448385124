mat-progress-spinner {
  &.success {
    --mdc-circular-progress-active-indicator-color: var(
      --UI-DS-Semantic-Success-300
    );
  }

  &.error {
    --mdc-circular-progress-active-indicator-color: var(
      --UI-DS-Semantic-Error-300
    );
  }
}
