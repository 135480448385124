mat-tab-group {
  height: 100%;
  &.custom-tab {
    --mdc-tab-indicator-active-indicator-height: 2px;
    --mat-tab-header-label-text-tracking: normal;
    --mat-tab-header-inactive-label-text-color: var(--UI-DS-Gray-500);
    --mat-tab-header-active-label-text-color: var(--UI-DS-Brand-700);
    overflow: hidden;
    height: 100%;
    width: 100%;

    .mat-mdc-tab-body-content {
      height: 100%;
      overflow: hidden;
      display: flex;
    }

    .mat-mdc-tab-body-wrapper {
      height: 100%;
      border-top: var(--border-1-gray-200);
    }

    .mat-mdc-tab-header {
      width: fit-content;
      --mat-tab-header-label-text-size: var(--font-size-14);
      --mat-tab-header-label-text-tracking: normal;
      --mat-tab-header-label-text-line-height: var(--line-height-20);
      --mat-tab-header-label-text-weight: var(--font-weight-600);
    }

    .mdc-tab-indicator__content {
      border-top-width: 2px;
      opacity: 1;
      border-color: transparent;
    }
    .mat-mdc-tab.mdc-tab--active .mdc-tab-indicator__content--underline {
      border-color: var(--UI-DS-Brand-700);
    }
  }

  &.add-tab {
    .mat-mdc-tab-header {
      padding: 0 var(--dim-lg) var(--dim-md);
    }
    .mat-mdc-tab-body-wrapper {
      height: calc(100% - 126px);
    }
  }

  &.custom-tabs {
    .mat-mdc-tab-header {
      --mdc-secondary-navigation-tab-container-height: 36px;
    }
    .mat-mdc-tab-label-container {
      --mat-tab-header-label-text-weight: var(--font-weight-600);
      --mat-tab-header-label-text-line-height: var(--line-height-143);
      --mat-tab-header-label-text-tracking: var(--letter-spacing-028);
      color: var(--UI-DS-Gray-500);
      font: var(--body-sm-semibold);
    }
    .mat-mdc-tab-labels {
      border: var(--border-1-gray-200);
      border-radius: var(--border-radius-xs);
      background: var(--UI-DS-Gray-50);
    }
    .mdc-tab--active {
      color: var(--UI-DS-Gray-700);
      border: var(--border-1-gray-300);
      border-radius: var(--border-radius-xs);
      background: var(--UI-DS-Base-White);
    }
  }

  &.new-ds {
    --mdc-tab-indicator-active-indicator-color: transparent;
    --mat-tab-header-active-hover-indicator-color: transparent;
    --mat-tab-header-active-focus-indicator-color: transparent;
    --mat-tab-header-active-focus-label-text-color: var(--UI-DS-Gray-700);
    --mat-tab-header-active-label-text-color: var(--UI-DS-Gray-700);
    --mat-tab-header-active-hover-label-text-color: var(--UI-DS-Gray-700);
    --mat-tab-header-active-ripple-color: transparent;
    --mat-tab-header-inactive-ripple-color: transparent;
  }
}
