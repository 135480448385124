.ag-theme-dmc {
  --ag-grid-size: var(--font-size-12);
  --ag-borders: 1px solid;
  --ag-border-radius: var(--border-radius-ssm);
  --ag-border-color: var(--UI-DS-Gray-300);
  --ag-foreground-color: var(--UI-DS-Gray-600);
  --ag-background-color: var(--UI-DS-Base-White);
  --ag-data-color: var(--UI-DS-Gray-600);
  --ag-header-foreground-color: var(--UI-DS-Gray-600);
  --ag-header-background-color: var(--UI-DS-Gray-50);
  --ag-row-hover-color: var(--UI-DS-Gray-100);
  // --ag-column-hover-color: transparent;
  --ag-range-selection-border-style: none;

  --ag-font-size: var(--font-size-14);
  --ag-font-family: var(--font-family-sora);
  --ag-row-height: 64px;
  --ag-header-height: 44px;
  --ag-borders-input: none;
  --ag-borders-input-radius: var(--border-radius-xxs);
  --ag-input-border-color: transparent;
  --ag-input-focus-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  --ag-input-focus-border-color: none;
  --ag-cell-horizontal-padding: var(--dim-md);
  --ag-header-column-separator-display: none;
  --ag-header-cell-hover-background-color: transparent;

  .ag-header-cell {
    font: var(--body-xs-medium);
    color: var(--UI-DS-Gray-800);
  }

  .ag-row.pointer:hover {
    cursor: pointer;
  }

  &:not(.without-padd-first-row).ag-header-cell.ag-column-first {
    padding-left: calc(
      var(--ag-row-group-indent-size) + var(--ag-cell-horizontal-padding)
    );
  }

  &.data-editor {
    .ag-ltr .ag-cell {
      --ag-cell-horizontal-border: var(--border-1-gray-300);
    }

    .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
      border-right: var(--border-1-gray-300);
    }
  }

  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border: none;
  }

  .ag-theme-dmc .ag-cell-inline-editing {
    border: none;
  }

  .ag-cell {
    &.invalid {
      background: #fef3f2;
    }

    &.role {
      font: var(--body-sm-medium);
      color: var(--UI-DS-Gray-800);
      line-height: min(
        var(--ag-internal-calculated-line-height),
        var(--ag-internal-padded-row-height)
      );
    }

    &.metric {
      font: var(--body-md-medium);
      color: var(--UI-DS-Base-Black);
      line-height: min(
        var(--ag-internal-calculated-line-height),
        var(--ag-internal-padded-row-height)
      );
    }

    &.cell-error {
      color: var(--UI-DS-Gray-600);
      background-color: var(--UI-DS-Semantic-Error-50);

      &:hover {
        background: var(--UI-DS-Semantic-Error-100);
      }

      &.ag-cell-inline-editing .ag-cell-editor input {
        border: var(--border-1-5-Error-600);
      }
    }

    &.id-medium {
      font: var(--body-sm-medium);
      color: var(--UI-DS-Gray-800);
      display: flex;
      align-items: center;
    }
  }

  .ag-cell:hover {
    background: var(--UI-DS-Brand-25);
    &.invalid {
      background: var(--UI-DS-Semantic-Error-100);
    }
  }

  &.list {
    --ag-font-size: var(--font-size-14);
  }
}
