mat-progress-bar {
  &.gauge {
    border-radius: var(--border-radius-6);
  }

  &.progress {
    --mdc-linear-progress-track-height: 8px;
    --mdc-linear-progress-active-indicator-height: 8px;
    --mdc-linear-progress-track-shape: var(--border-radius-xxs);
    border-radius: var(--border-radius-xxs);
  }
}
