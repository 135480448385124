::-webkit-scrollbar,
scrollbar {
  width: var(--dim-mds);
  box-sizing: border-box;
}

::-webkit-scrollbar-thumb,
scrollbar-thumb {
  border-radius: var(--dim-mds);
  box-shadow: inset 0 0 var(--dim-mds) var(--dim-mds) var(--UI-DS-Gray-200);
  border: var(--border-scrollbar);
}

::-webkit-scrollbar-track,
scrollbar-track {
  background-color: transparent;
}
