dmc-ng-input.input-suffix {
  .mat-mdc-form-field-infix {
    input {
      width: 95%;
    }
  }
}

dmc-ng-select-country {
  mat-form-field .mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: var(--dim-mds);
  }
}

mat-form-field {
  .mdc-notched-outline--notched .mdc-notched-outline__notch {
    border-top: var(--border-1-gray-300);
  }
  --mat-form-field-container-vertical-padding: var(--dim-xs);
  &:not(.area) {
    --mat-form-field-container-height: 48px;
  }
  --mat-form-field-container-text-font: var(--font-family-sora);
  --mat-form-field-container-text-line-height: var(--line-height-20);
  --mat-form-field-container-text-size: var(--font-size-14);
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: normal;

  --mat-form-field-outlined-label-text-populated-size: var(--font-size-14);

  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 8px;
  --mdc-filled-text-field-error-hover-label-text-color: var(
    --UI-DS-Semantic-Error-500
  );
  --mdc-filled-text-field-error-focus-label-text-color: var(
    --UI-DS-Semantic-Error-500
  );
  --mdc-filled-text-field-error-label-text-color: var(
    --UI-DS-Semantic-Error-500
  );
  --mdc-filled-text-field-error-caret-color: var(--UI-DS-Semantic-Error-500);
  --mdc-filled-text-field-error-active-indicator-color: var(
    --UI-DS-Semantic-Error-500
  );
  --mdc-filled-text-field-error-focus-active-indicator-color: var(
    --UI-DS-Semantic-Error-500
  );
  --mdc-filled-text-field-error-hover-active-indicator-color: var(
    --UI-DS-Semantic-Error-500
  );

  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 8px;
  --mdc-outlined-text-field-label-text-size: var(--font-size-14);
  --mdc-outlined-text-field-error-caret-color: var(--UI-DS-Semantic-Error-500);
  --mdc-outlined-text-field-error-hover-outline-color: var(
    --UI-DS-Semantic-Error-500
  );
  --mdc-outlined-text-field-error-outline-color: var(
    --UI-DS-Semantic-Error-500
  );

  --mat-select-trigger-text-line-height: var(--line-height-20);
  --mat-select-trigger-text-size: var(--font-size-14);
  --mat-select-trigger-text-weight: normal;
  --mat-select-trigger-text-tracking: normal;

  --mat-form-field-error-text-color: var(--UI-DS-Semantic-Error-600);

  .mat-mdc-form-field-required-marker {
    display: none;
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-icon-suffix .mat-icon {
    color: var(--UI-DS-Gray-500);

    &.arrow-down {
      --icon-size: 20px;
      height: var(--icon-size);
      width: var(--icon-size);
    }
  }

  &:not(.area) {
    .mat-mdc-form-field-infix {
      height: 48px;
      max-height: 48px;
      display: flex;
      align-items: center;
    }
  }

  &.file-upload .mat-mdc-text-field-wrapper {
    padding-right: 0px;
  }

  &.row-form {
    &.smallable {
      max-width: 220px;
    }
    .mat-mdc-text-field-wrapper {
      max-height: 48px;
    }
  }

  mat-select {
    .mat-mdc-select-arrow {
      display: none;
    }
  }

  &.small {
    --mat-form-field-container-height: 24px;
    --mat-select-trigger-text-size: var(--font-size-12);
    --mat-select-trigger-text-line-height: var(--line-height-15);
    --mat-select-enabled-trigger-text-color: var(--UI-DS-Gray-900);
    width: 125px;

    .mat-mdc-form-field-infix {
      padding: 0;
    }

    .mat-mdc-form-field-flex {
      height: 24px;
    }

    .mdc-text-field--outlined {
      --mdc-outlined-text-field-outline-width: 0px;
      --mdc-outlined-text-field-focus-outline-width: 0px;
      padding-left: 0;
    }
  }

  &.cell {
    .mdc-text-field--outlined {
      --mdc-outlined-text-field-outline-width: 0px;
      --mdc-outlined-text-field-focus-outline-width: 0px;
      padding-left: var(--dim-sm);
    }

    --mat-select-trigger-text-size: var(--font-size-14);
    --mat-select-trigger-text-line-height: var(--line-height-20);
    --mat-select-enabled-trigger-text-color: var(--UI-DS-Gray-600);
  }

  &.sm {
    --mat-form-field-container-height: 40px;

    .mat-mdc-form-field-infix {
      padding: 8px 8px;
      height: 40px;
    }

    .mat-mdc-form-field-flex {
      height: 40px;
    }
  }

  &.multi-select-chips {
    .mat-mdc-form-field-infix {
      padding: 3px 0;
    }

    mat-autocomplete.multi-select-chips-autocomplete {
      .autocomplete-list {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
    }
  }

  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
    .mdc-notched-outline--upgraded
    .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(
        calc(calc(-4.25px + var(--mat-form-field-container-height) / 2) * -1)
      )
      scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  }

  &.label-custom .mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: var(--dim-mds);
  }
}

mat-option {
  --mat-option-label-text-color: var(--UI-DS-Gray-900);
  --mat-option-label-text-line-height: var(--line-height-18);
  --mat-option-label-text-size: var(--font-size-12);
  --mat-option-label-text-weight: var(--font-weight-500);
  --mat-option-selected-state-label-text-color: var(--UI-DS-Gray-900);

  &.mat-mdc-option {
    min-height: 40px;
    padding-left: var(--dim-ssm);
    padding-right: var(--dim-ssm);

    &.mdc-list-item--selected {
      background-color: var(--UI-DS-Gray-50);
    }
  }

  .mat-pseudo-checkbox {
    height: 20px;
    width: 20px;
    border-radius: var(--border-radius-xs);
    --mat-full-pseudo-checkbox-unselected-icon-color: var(--UI-DS-Gray-300);
  }

  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
    width: 6px;
    height: 2px;
  }

  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
    width: 12px;
    height: 5px;
    top: -6.242641px;
  }

  &.small {
    --mat-option-label-text-line-height: var(--line-height-15);
    --mat-option-label-text-size: var(--font-size-12);

    .mat-pseudo-checkbox {
      width: 12px;
      height: 12px;
      border: none;
    }

    .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
      width: 7px;
      height: 3px;
    }
  }

  &.multi-select-option {
    height: 32px;
    padding: 0 !important;
    margin: 0 !important;

    &.loading-option {
      padding: 0 !important;
      height: 4px;
      min-height: 4px;
    }

    .mdc-list-item__primary-text {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .click-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .option-container {
      width: 100%;
      border-radius: var(--border-radius-10);
      padding: 0 var(--dim-10);
      background-color: var(--UI-DS-Base-White);
      margin: 0 var(--dim-6);

      &.selected {
        border-radius: var(--border-radius-6);
        background-color: var(--UI-DS-Gray-50);
      }

      &:hover {
        background-color: var(--UI-DS-Gray-25);
      }
    }
  }
}

.mat-mdc-select-panel-above div.mat-mdc-select-panel,
.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel,
.multi-select-chips-autocomplete {
  border-radius: var(--border-radius-xs) !important;
  margin: var(--dim-xxs) 0px;
  padding: var(--dim-xxs) 0px;
  border: var(--border-1-gray-200);
  box-shadow: var(--shadow-lg);
  display: flex;
  flex-direction: column;
  gap: 2px;

  .mat-mdc-option {
    --mat-option-selected-state-layer-color: var(--UI-DS-Gray-50);
    border-radius: var(--border-radius-6);
    padding: 0 var(--dim-10);
    margin: 0 var(--dim-xs);
    &:hover {
      --mat-option-selected-state-layer-color: var(--UI-DS-Gray-25);
    }
  }

  .input-autocomplete {
    display: flex;
    padding: var(--dim-xs);
  }

  .autocomplete-list {
    max-height: calc(100% - 60px);
    overflow-y: auto;
    min-height: 35px;
    display: flex;
    flex-direction: column;
    gap: 2px;

    mat-option.loading-option {
      height: 100%;
      min-height: inherit;
      pointer-events: none;
    }

    mat-option.invisible {
      height: 0px;
      min-height: 0px;
      pointer-events: none;
    }

    mat-option.no-result {
      height: 100%;
      min-height: inherit;
      pointer-events: none;
      padding-left: 24px;
    }
    mat-option:hover {
      --mat-option-hover-state-layer-color: transparent;
    }
  }

  .search-icon {
    --icon-size: 24px;
    font-size: var(--font-size-24);
    height: var(--icon-size);
    width: var(--icon-size);
    color: var(--UI-DS-Gray-500);
  }
}
