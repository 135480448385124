table {
  th {
    vertical-align: middle;
  }

  .mat-mdc-header-row {
    --mat-table-header-container-height: 56px;
    --mat-table-header-headline-color: var(--UI-DS-Gray-800);
    --mat-table-header-headline-line-height: var(--line-height-15);
    --mat-table-header-headline-size: var(--font-size-12);
    --mat-table-header-headline-weight: var(--font-weight-600);
    --mat-table-row-item-outline-color: var(--UI-DS-Gray-300);
    --mat-table-row-item-label-text-size: var(--font-size-12);
    background-color: var(--UI-DS-Gray-100);
    border-bottom: var(--border-1-gray-300);
  }

  .mat-mdc-header-cell {
    border-bottom-style: none;
  }

  .mdc-data-table__cell {
    padding: var(--dim-ssm) var(--dim-sm);
    vertical-align: middle;
  }

  .mat-mdc-row {
    --mat-table-row-item-container-height: 56px;

    &:hover {
      background-color: var(--UI-DS-Gray-100);
    }
  }
}
