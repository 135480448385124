@use 'sass:math';

:root {
  --border-radius-xxs: var(--dim-xxs);
  --border-radius-xs: var(--dim-xs);
  --border-radius-sm: var(--dim-sm);
  --border-radius-ssm: var(--dim-ssm);
  --border-radius-md: var(--dim-md);
  --border-radius-xl: var(--dim-xl);
  --border-error: solid var(--dim-pixel) var(--UI-DS-Semantic-Error-500);
  --border-warning: solid var(--dim-pixel) var(--UI-DS-Semantic-Warning-500);
  --border-informative: solid var(--dim-pixel) var(--semantic-informative-500);
  --border-scrollbar: solid 7px transparent;
  --border-radius-half: 50%;

  // new DS:
  --border-radius-2: var(--dim-2);
  --border-radius-6: var(--dim-6);
  --border-radius-10: var(--dim-10);

  // WHITE
  --border-1-5-white: 1.5px solid var(--UI-DS-Base-White);
  --border-4-white: 4px solid var(--UI-DS-Base-White);

  // GRAY
  // 1px
  --border-1-gray-100: 1px solid var(--UI-DS-Gray-100);
  --border-1-gray-200: 1px solid var(--UI-DS-Gray-200);
  --border-1-gray-300: 1px solid var(--UI-DS-Gray-300);
  --border-1-gray-600: 1px solid var(--UI-DS-Gray-600);
  --border-1-gray-700: 1px solid var(--UI-DS-Gray-700);
  // 2px
  --border-2-gray-50: 2px solid var(--UI-DS-Gray-50);
  --border-2-gray-100: 2px solid var(--UI-DS-Gray-100);
  --border-2-gray-200: 2px solid var(--UI-DS-Gray-200);
  // 4px
  --border-4-gray-50: 4px solid var(--UI-DS-Gray-50);
  // 6px
  --border-6-gray-50: 6px solid var(--UI-DS-Gray-50);
  // 8px
  --border-8-gray-50: 8px solid var(--UI-DS-Gray-50);
  // 10px
  --border-10-gray-50: 10px solid var(--UI-DS-Gray-50);

  // BRAND
  // 1px
  --border-1-brand-200: 1px solid var(--UI-DS-Brand-200);
  --border-1-brand-500: 1px solid var(--UI-DS-Brand-500);
  // 2px
  --border-2-brand-50: 2px solid var(--UI-DS-Brand-50);
  --border-2-brand-400: 2px solid var(--UI-DS-Brand-400);
  --border-2-brand-500: 2px solid var(--UI-DS-Brand-500);
  --border-2-brand-600: 2px solid var(--UI-DS-Brand-600);
  // 4px
  --border-4-brand-50: 4px solid var(--UI-DS-Brand-50);
  // 6px
  --border-6-brand-50: 6px solid var(--UI-DS-Brand-50);
  // 8px
  --border-8-brand-50: 8px solid var(--UI-DS-Brand-50);
  // 10px
  --border-10-brand-50: 10px solid var(--UI-DS-Brand-50);

  // WARNING
  --border-2-warning-50: 2px solid var(--UI-DS-Semantic-Warning-50);
  --border-4-warning-50: 4px solid var(--UI-DS-Semantic-Warning-50);
  --border-6-warning-50: 6px solid var(--UI-DS-Semantic-Warning-50);
  --border-8-warning-50: 8px solid var(--UI-DS-Semantic-Warning-50);
  --border-10-warning-50: 10px solid var(--UI-DS-Semantic-Warning-50);

  // ERROR
  --border-1-error-100: 1px solid var(--UI-DS-Semantic-Error-100);
  --border-1-error-300: 1px solid var(--UI-DS-Semantic-Error-300);
  --border-1-5-Error-600: 1.5px solid var(--UI-DS-Semantic-Error-600);
  --border-2-error-500: 2px solid var(--UI-DS-Semantic-Error-500);
  --border-2-error-50: 2px solid var(--UI-DS-Semantic-Error-50);
  --border-4-error-50: 4px solid var(--UI-DS-Semantic-Error-50);
  --border-6-error-50: 6px solid var(--UI-DS-Semantic-Error-50);
  --border-8-error-50: 8px solid var(--UI-DS-Semantic-Error-50);
  --border-10-error-50: 10px solid var(--UI-DS-Semantic-Error-50);

  // SUCCESS
  --border-2-success-50: 2px solid var(--UI-DS-Semantic-Success-50);
  --border-4-success-50: 4px solid var(--UI-DS-Semantic-Success-50);
  --border-6-success-50: 6px solid var(--UI-DS-Semantic-Success-50);
  --border-8-success-50: 8px solid var(--UI-DS-Semantic-Success-50);
  --border-10-success-50: 10px solid var(--UI-DS-Semantic-Success-50);
}
