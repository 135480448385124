image-cropper {
  --cropper-overlay-color: var(--UI-DS-Gray-100);

  & > div {
    background-color: var(--UI-DS-Gray-100);
  }

  .ngx-ic-cropper {
    color: var(--UI-DS-Gray-600) !important;

    .ngx-ic-resize {
      z-index: 2 !important;
      .ngx-ic-square {
        border: var(--border-1-gray-300) !important;
        background-color: var(--UI-DS-Gray-600) !important;
      }
    }
  }
}
