.mat-mdc-tooltip {
  box-shadow: var(--shadow-lg);

  &::before {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 8px;
  }

  &.black {
    --mdc-plain-tooltip-supporting-text-color: var(--UI-DS-Base-White);
    --mdc-plain-tooltip-container-color: var(--UI-DS-Gray-800);

    &::before {
      border-color: var(--UI-DS-Gray-800) transparent transparent transparent;
    }
  }

  &.white {
    --mdc-plain-tooltip-supporting-text-color: var(--UI-DS-Gray-600);
    --mdc-plain-tooltip-container-color: var(--UI-DS-Base-White);

    &::before {
      border-color: var(--UI-DS-Base-White) transparent transparent transparent;
    }
  }

  &.above::before {
    bottom: -8px;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  &.below::before {
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
  }

  &.right::before {
    left: -12px !important;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
  }

  &.left::before {
    left: auto;
    right: -12px !important;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
  }
}

.mdc-tooltip__surface {
  padding: var(--dim-ssm) !important;
  --mdc-plain-tooltip-supporting-text-font: var(--font-family-sora);
  --mdc-plain-tooltip-supporting-text-line-height: var(--line-height-18);
  --mdc-plain-tooltip-supporting-text-size: var(--font-size-12);
  --mdc-plain-tooltip-supporting-text-weight: var(--font-weight-400);
  --mdc-plain-tooltip-container-shape: var(--border-radius-xs);
}
