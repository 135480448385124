:root {
  --dim-pixel: 1px;
  --dim-2: 2px;
  --dim-xxs: 4px;
  --dim-6: 6px;
  --dim-xs: 8px;
  --dim-10: 10px;
  --dim-ssm: 12px;
  --dim-14: 14px;
  --dim-sm: 16px;
  --dim-mds: 20px;
  --dim-md: 24px;
  --dim-lg: 32px;
  --dim-xl: 40px;
  --dim-xxl: 48px;
  --dim-xxxl: 56px;
}
