:root {
  --box-shadow-default: 0 10px 40px 0 rgba(46, 20, 82, 0.06);
  --box-shadow-button: 0 4px 24px 0 rgba(56, 54, 191, 0.2);
  --box-shadow-slider: 0 2px 5px 0 rgba(46, 20, 82, 0.15);
  --box-shadow-pagination: 0px -10px 10px -17px rgba(46, 20, 82, 1);

  --shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  --shadow-sm: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  --shadow-md: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  --shadow-lg: 0px 12px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  --shadow-xl: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  --shadow-2xl: 0px 24px 48px -12px rgba(16, 24, 40, 0.25);
  --shadow-3xl: 0px 32px 64px -12px rgba(16, 24, 40, 0.2);

  --shadow-brand-outline-400: 0px 0px 0px 2px var(--UI-DS-Base-White),
    0px 0px 0px 4px var(--UI-DS-Brand-400);
}
