.mat-mdc-menu-panel {
  --mat-menu-container-shape: var(--border-radius-ssm);
  border: var(--border-1-gray-300);
  // box-shadow: ;
  --mat-app-elevation-shadow-level-8: var(--box-shadow-button);

  .mat-mdc-menu-content {
    padding: 0;
    .mat-mdc-menu-item {
      color: var(--UI-DS-Gray-900);
      --mat-menu-item-label-text-font: var(--font-family-sora);
      --mat-menu-item-label-text-line-height: var(--line-height-143);
      --mat-menu-item-label-text-size: var(--font-size-14);
      --mat-menu-item-label-text-weight: normal;
      mat-icon {
        --icon-size: 20px;
        width: var(--icon-size);
        height: var(--icon-size);
        font-size: var(--icon-size);
        color: var(--UI-DS-Gray-900);
      }
    }
  }

  &.user-menu {
    --mat-menu-container-shape: var(--border-radius-sm);
    background-color: var(--UI-DS-Gray-900);
    max-width: none !important;
    left: 80px;
    bottom: -30px;
    position: relative;
    border: var(--border-1-gray-600);
    &.expanded {
      left: 215px;
    }
    .mat-mdc-menu-content {
      display: flex;
      flex-direction: column;
      .mat-mdc-menu-item {
        color: var(--UI-DS-Gray-300);
        --mat-menu-item-label-text-font: var(--font-family-sora);
        --mat-menu-item-label-text-line-height: var(--line-height-143);
        --mat-menu-item-label-text-size: var(--font-size-14);
        --mat-menu-item-label-text-weight: normal;
        mat-icon {
          --icon-size: 20px;
          width: var(--icon-size);
          height: var(--icon-size);
          font-size: var(--icon-size);
          color: var(--UI-DS-Gray-300);
        }

        &:hover {
          color: var(--UI-DS-Gray-100);
          background-color: var(--UI-DS-Gray-800);
          mat-icon.gray-stroke-400 svg path {
            stroke: var(--UI-DS-Gray-100);
          }
        }
      }
      .user-bloc {
        display: flex;
        flex-direction: row;
        gap: var(--dim-sm);
        padding: var(--dim-sm);
        border-bottom: var(--border-1-gray-700);

        .user-info {
          display: flex;
          flex-direction: column;
          .name {
            font: var(--body-md-medium);
            color: var(--UI-DS-Gray-300);
          }
          span {
            font: var(--body-sm-regular);
            color: var(--UI-DS-Gray-300);
          }
        }
      }
    }
  }
}
