/* You can add global styles to this file, and also import other style files */
@import 'sass/style';
@import 'components/style';
@import 'tokens/tokens-animation';
@import 'tokens/tokens-border';
@import 'tokens/tokens-color';
@import 'tokens/tokens-filters';
@import 'tokens/tokens-font';
@import 'tokens/tokens-shadow';
@import 'tokens/tokens-size';
@import 'tokens/token-icon';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round';
@import 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;700&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;700&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600;700&display=swap"';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-material.css';
@import 'ag-grid-community/styles/ag-theme-quartz.css';

html {
  height: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

details > summary,
ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  line-height: 1;
}

body,
body .p-component {
  font-family: var(--font-family-inter);
}

ul,
a.menu-item {
  list-style: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: currentColor;
}

.sidenav-container {
  .custom {
    span.mdc-list-item__content {
      height: 100%;
    }
  }
}

// FLEXBOX UTILS
.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-grow {
  flex-grow: 1;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-auto {
  overflow: auto;
}
