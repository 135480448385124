@use 'sass:math';

@function px-to-rem($value) {
  @return math.div($value, 16) + rem;
}

:root {
  // font-family
  --font-family-inter: Inter, sans-serif;
  --font-family-sora: 'Sora', sans-serif;
  --font-family-roboto: 'Roboto', sans-serif;

  // font-size
  --font-size-2-cqw: 2cqw;
  --font-size-25-cqw: 2.5cqw;
  --font-size-10: #{px-to-rem(10)};
  --font-size-12: #{px-to-rem(12)};
  --font-size-14: #{px-to-rem(14)};
  --font-size-16: #{px-to-rem(16)};
  --font-size-18: #{px-to-rem(18)};
  --font-size-20: #{px-to-rem(20)};
  --font-size-24: #{px-to-rem(24)};
  --font-size-30: #{px-to-rem(30)};
  --font-size-32: #{px-to-rem(32)};
  --font-size-36: #{px-to-rem(36)};
  --font-size-40: #{px-to-rem(40)};
  --font-size-48: #{px-to-rem(48)};
  --font-size-60: #{px-to-rem(60)};

  // line-height
  --line-height-1: 1;
  --line-height-11: 1.1;
  --line-height-122: 1.22;
  --line-height-143: 1.43;
  --line-height-15: 1.5;
  --line-height-14: #{px-to-rem(14)};
  --line-height-18: #{px-to-rem(18)};
  --line-height-20: #{px-to-rem(20)};
  --line-height-24: #{px-to-rem(24)};
  --line-height-28: #{px-to-rem(28)};
  --line-height-30: #{px-to-rem(30)};
  --line-height-32: #{px-to-rem(32)};
  --line-height-38: #{px-to-rem(38)};
  --line-height-44: #{px-to-rem(44)};
  --line-height-60: #{px-to-rem(60)};
  --line-height-110-percent: 110%;
  --line-height-130-percent: 130%;

  // letter-spacing
  --letter-spacing-028: #{px-to-rem(0.28)};
  --letter-spacing-056: #{px-to-rem(0.56)};
  --letter-spacing-064: #{px-to-rem(0.64)};
  --letter-spacing-minus-064: #{px-to-rem(-0.64)};
  --letter-spacing-minus-072: #{px-to-rem(-0.72)};
  --letter-spacing-minus-09: #{px-to-rem(-0.9)};
  --letter-spacing-minus-096: #{px-to-rem(-0.96)};
  --letter-spacing-minus-108: #{px-to-rem(-1.08)};
  --letter-spacing-minus-12: #{px-to-rem(-1.2)};
  --letter-spacing-minus-144: #{px-to-rem(-1.44)};

  // font-weight
  --font-weight-700: 700;
  --font-weight-600: 600;
  --font-weight-500: 500;
  --font-weight-400: 400;

  // BODY XXS
  --body-xxs-regular: normal var(--font-weight-400) var(--font-size-10) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-xxs-medium: normal var(--font-weight-500) var(--font-size-10) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-xxs-semibold: normal var(--font-weight-600) var(--font-size-10) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-xxs-bold: normal var(--font-weight-700) var(--font-size-10) /
    var(--line-height-130-percent) var(--font-family-sora);

  // BODY XS
  --body-xs-regular: normal var(--font-weight-400) var(--font-size-12) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-xs-medium: normal var(--font-weight-500) var(--font-size-12) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-xs-semibold: normal var(--font-weight-600) var(--font-size-12) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-xs-bold: normal var(--font-weight-700) var(--font-size-12) /
    var(--line-height-130-percent) var(--font-family-sora);

  // BODY SM
  --body-sm-regular: normal var(--font-weight-400) var(--font-size-14) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-sm-medium: normal var(--font-weight-500) var(--font-size-14) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-sm-semibold: normal var(--font-weight-600) var(--font-size-14) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-sm-bold: normal var(--font-weight-700) var(--font-size-14) /
    var(--line-height-130-percent) var(--font-family-sora);

  // BODY MD
  --body-md-regular: normal var(--font-weight-400) var(--font-size-16) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-md-medium: normal var(--font-weight-500) var(--font-size-16) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-md-semibold: normal var(--font-weight-600) var(--font-size-16) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-md-bold: normal var(--font-weight-700) var(--font-size-16) /
    var(--line-height-130-percent) var(--font-family-sora);

  // BODY LG
  --body-lg-regular: normal var(--font-weight-400) var(--font-size-18) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-lg-medium: normal var(--font-weight-500) var(--font-size-18) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-lg-semibold: normal var(--font-weight-600) var(--font-size-18) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-lg-bold: normal var(--font-weight-700) var(--font-size-18) /
    var(--line-height-130-percent) var(--font-family-sora);

  // BODY XL
  --body-xl-regular: normal var(--font-weight-400) var(--font-size-20) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-xl-medium: normal var(--font-weight-500) var(--font-size-20) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-xl-semibold: normal var(--font-weight-600) var(--font-size-20) /
    var(--line-height-130-percent) var(--font-family-sora);
  --body-xl-bold: normal var(--font-weight-700) var(--font-size-20) /
    var(--line-height-130-percent) var(--font-family-sora);

  // TITLE H1
  --title-h1-regular: normal var(--font-weight-400) var(--font-size-48) /
    var(--line-height-110-percent) var(--font-family-sora);
  --title-h1-medium: normal var(--font-weight-500) var(--font-size-48) /
    var(--line-height-110-percent) var(--font-family-sora);
  --title-h1-semibold: normal var(--font-weight-600) var(--font-size-48) /
    var(--line-height-110-percent) var(--font-family-sora);
  --title-h1-bold: normal var(--font-weight-700) var(--font-size-48) /
    var(--line-height-110-percent) var(--font-family-sora);

  // TITLE H2
  --title-h2-regular: normal var(--font-weight-400) var(--font-size-40) /
    var(--line-height-110-percent) var(--font-family-sora);
  --title-h2-medium: normal var(--font-weight-500) var(--font-size-40) /
    var(--line-height-110-percent) var(--font-family-sora);
  --title-h2-semibold: normal var(--font-weight-600) var(--font-size-40) /
    var(--line-height-110-percent) var(--font-family-sora);
  --title-h2-bold: normal var(--font-weight-700) var(--font-size-40) /
    var(--line-height-110-percent) var(--font-family-sora);

  // TITLE H3
  --title-h3-regular: normal var(--font-weight-400) var(--font-size-32) /
    var(--line-height-110-percent) var(--font-family-sora);
  --title-h3-medium: normal var(--font-weight-500) var(--font-size-32) /
    var(--line-height-110-percent) var(--font-family-sora);
  --title-h3-semibold: normal var(--font-weight-600) var(--font-size-32) /
    var(--line-height-110-percent) var(--font-family-sora);
  --title-h3-bold: normal var(--font-weight-700) var(--font-size-32) /
    var(--line-height-110-percent) var(--font-family-sora);

  // TITLE H4
  --title-h4-regular: normal var(--font-weight-400) var(--font-size-24) /
    var(--line-height-110-percent) var(--font-family-sora);
  --title-h4-medium: normal var(--font-weight-500) var(--font-size-24) /
    var(--line-height-110-percent) var(--font-family-sora);
  --title-h4-semibold: normal var(--font-weight-600) var(--font-size-24) /
    var(--line-height-110-percent) var(--font-family-sora);
  --title-h4-bold: normal var(--font-weight-700) var(--font-size-24) /
    var(--line-height-110-percent) var(--font-family-sora);

  // OTHER
  --title-reset-password: normal var(--font-weight-500) var(--font-size-36) /
    var(--line-height-44) var(--font-family-sora);

  --title-error-page: normal var(--font-weight-500) var(--font-size-60) /
    var(--line-height-60) var(--font-family-sora);

  --price-plan: normal var(--font-weight-500) var(--font-size-30) /
    var(--line-height-38) var(--font-family-sora);

  --link-xs: normal var(--font-weight-600) var(--font-size-14) /
    var(--line-height-143) var(--font-family-sora);

  --robot-medium: normal var(--font-weight-500) var(--font-size-12) /
    var(--line-height-122) var(--font-family-roboto);

  --robot-regular: normal normal var(--font-size-12) / var(--line-height-122)
    var(--font-family-roboto);

  --phone-xs: normal normal var(--font-size-2-cqw) / var(--line-height-11)
    var(--font-family-roboto);

  --phone-message: normal normal var(--font-size-25-cqw) /
    var(--line-height-143) var(--font-family-sora);
}
