mat-icon {
  &.grey-stroke {
    svg path {
      stroke: var(--UI-DS-Gray-400);
    }
  }

  &.black-stroke {
    svg path {
      stroke: var(--UI-DS-Gray-900);
    }
  }

  &.yellow-stroke {
    svg path {
      stroke: var(--ui-colors-semantic-warning-500);
    }
  }

  &.primary-stroke {
    svg path {
      stroke: var(--UI-DS-Brand-500);
    }
  }

  &.success {
    svg path {
      stroke: var(--UI-DS-Semantic-Success-500);
    }
  }

  &.error {
    svg path {
      stroke: var(--UI-DS-Semantic-Error-500);
    }
  }

  &.warning {
    svg path {
      stroke: var(--UI-DS-Semantic-Warning-500);
    }
  }

  &.info {
    svg path {
      stroke: var(--semantic-informative-500);
    }
  }

  &.informations {
    --icon-size: 24px;
    min-height: var(--icon-size);
    min-width: var(--icon-size);
    font-size: var(--icon-size);

    &.default-info {
      svg path {
        stroke: var(--UI-DS-Gray-600);
      }
    }
  }

  &.toolbar-icon {
    stroke: var(--UI-DS-Gray-500);
    &:hover {
      cursor: pointer;
      svg path {
        stroke: var(--UI-DS-Gray-800);
      }
    }
  }

  // new DS
  &.white-stroke-new {
    svg path {
      stroke: var(--UI-DS-Base-White);
    }
  }

  &.brand-stroke-500 {
    svg path {
      stroke: var(--UI-DS-Brand-500);
    }
  }

  &.brand-stroke-600 {
    svg path {
      stroke: var(--UI-DS-Brand-600);
    }
  }

  &.gray-stroke-100 {
    svg path {
      stroke: var(--UI-DS-Gray-100);
    }
  }

  &.gray-stroke-300 {
    svg path {
      stroke: var(--UI-DS-Gray-300);
    }
  }

  &.gray-stroke-400 {
    svg path {
      stroke: var(--UI-DS-Gray-400);
    }
  }

  &.gray-stroke-500 {
    svg path {
      stroke: var(--UI-DS-Gray-500);
    }
  }

  &.gray-stroke-600 {
    svg path {
      stroke: var(--UI-DS-Gray-600);
    }
  }

  &.gray-stroke-700 {
    svg path {
      stroke: var(--UI-DS-Gray-700);
    }
  }

  &.gray-stroke-800 {
    svg path {
      stroke: var(--UI-DS-Gray-800);
    }
  }

  &.gray-stroke-900 {
    svg path {
      stroke: var(--UI-DS-Gray-900);
    }
  }

  &.semantic-success-stroke-400 {
    svg path {
      stroke: var(--UI-DS-Semantic-Success-400);
    }
  }

  &.semantic-error-stroke-600 {
    svg path {
      stroke: var(--UI-DS-Semantic-Error-600);
    }
  }
}
