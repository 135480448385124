mat-radio-group {
  &.small-row {
    display: flex;
    flex-direction: row;
    gap: var(--dim-xs);
  }

  &.squared {
    mat-radio-button {
      &.mat-mdc-radio-button {
        border-radius: var(--border-radius-xs);
      }
    }
  }

  &.bordered {
    mat-radio-button {
      &:hover {
        cursor: pointer;
      }

      &.mat-mdc-radio-button {
        padding: var(--dim-ssm) var(--dim-sm);
        border: var(--border-1-gray-300);

        &.selected {
          border: var(--border-1-brand-500);
          box-shadow: var(--shadow-brand-outline-400);
        }
      }
    }
  }
}

mat-radio-button {
  &.mat-mdc-radio-button {
    --mdc-form-field-label-text-line-height: var(--line-height-143);
    --mdc-form-field-label-text-tracking: normal;
    --mdc-form-field-label-text-weight: var(--font-weight-600);

    .mdc-radio {
      width: 24px;
      height: 24px;

      .mdc-radio__background {
        width: 24px;
        height: 24px;

        &::before {
          top: calc(-1 * (var(--mdc-radio-state-layer-size) - 24px) / 2);
          left: calc(-1 * (var(--mdc-radio-state-layer-size) - 24px) / 2);
        }

        .mdc-radio__outer-circle {
          border-width: 1.5px;
        }

        .mdc-radio__inner-circle {
          top: -4px;
          left: -4px;
          border-width: 16px;
        }
      }
    }

    &.md {
      --mdc-form-field-label-text-line-height: var(--line-height-15);
      --mdc-form-field-label-text-weight: normal;
      --mdc-radio-state-layer-size: 24px;
      --mat-radio-label-text-color: var(--UI-DS-Gray-800);
      --mat-radio-label-text-font: var(--font-family-sora);
      --mat-radio-label-text-line-height: var(--line-height-24);
      --mat-radio-label-text-size: var(--font-size-16);
      --mat-radio-label-text-weight: var(--font-weight-500);

      .mdc-radio {
        width: 20px;
        height: 20px;

        .mdc-radio__background {
          width: 20px;
          height: 20px;

          &::before {
            top: calc(-1 * (var(--mdc-radio-state-layer-size) - 20px) / 2);
            left: calc(-1 * (var(--mdc-radio-state-layer-size) - 20px) / 2);
          }

          .mdc-radio__outer-circle {
            border-width: 1px;
          }

          .mdc-radio__inner-circle {
            top: 0px;
            left: 0px;
            border-width: 10px;
          }
        }
      }

      &.mat-mdc-radio-checked .mdc-radio .mdc-radio__background {
        .mdc-radio__inner-circle {
          top: 0px;
          left: 0px;
          border-width: 0px;
        }
        .mdc-radio__outer-circle {
          border-width: 6px;
        }
      }
    }

    &.sm {
      --mdc-form-field-label-text-line-height: var(--line-height-15);
      --mdc-form-field-label-text-weight: normal;
      --mdc-radio-state-layer-size: 18px;
      --mat-radio-label-text-color: var(--UI-DS-Gray-800);
      --mat-radio-label-text-font: var(--font-family-sora);
      --mat-radio-label-text-line-height: var(--line-height-24);
      --mat-radio-label-text-size: var(--font-size-14);
      --mat-radio-label-text-weight: var(--font-weight-500);

      .mdc-radio {
        width: 16px;
        height: 16px;

        .mdc-radio__background {
          width: 16px;
          height: 16px;

          &::before {
            top: calc(-1 * (var(--mdc-radio-state-layer-size) - 16px) / 2);
            left: calc(-1 * (var(--mdc-radio-state-layer-size) - 16px) / 2);
          }

          .mdc-radio__outer-circle {
            border-width: 1px;
          }

          .mdc-radio__inner-circle {
            top: 0px;
            left: 0px;
            border-width: 10px;
          }
        }
      }

      &.mat-mdc-radio-checked .mdc-radio .mdc-radio__background {
        .mdc-radio__inner-circle {
          top: 0px;
          left: 0px;
          border-width: 0px;
        }
        .mdc-radio__outer-circle {
          border-width: 5px;
        }
      }
    }

    &.fake-disabled {
      --mdc-radio-disabled-selected-icon-color: var(--UI-DS-Brand-500);
      --mdc-radio-disabled-unselected-icon-color: var(--UI-DS-Brand-500);
      --mdc-radio-disabled-selected-icon-opacity: 1;
      --mat-radio-disabled-label-color: black;
    }

    &.space-beetween-label {
      &.mat-mdc-radio-button .mat-internal-form-field {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;

        &.mdc-form-field--align-end > label {
          width: 77px;
          margin-left: 0;
        }
      }
    }

    &.label-click {
      &.mat-mdc-radio-button .mat-internal-form-field {
        .mdc-label:hover {
          cursor: pointer;
        }
      }
    }
  }
}
