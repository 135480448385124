mat-snack-bar-container {
  --mdc-snackbar-container-color: var(--UI-DS-Base-White);

  .mat-mdc-snackbar-surface {
    --mdc-snackbar-container-shape: var(--border-radius-xs);
    box-shadow: var(--shadow-lg);
    border: var(--border-1-gray-300);
    padding: 0;

    .mdc-snackbar__label {
      padding: var(--dim-sm);
    }
  }
}
