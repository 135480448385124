@use 'sass:color';
@import '../functions/functions';

/* All variables */
:root {
  // specific colors
  --ui-colors-primary-blue-700-main: #3836bf;
  --ui-colors-primary-blue-500: #6664ff;
  --semantic-informative-100: #f2f7ff;
  --semantic-informative-500: #3672eb;
  --phone-info: #737373;

  // BASE
  --UI-DS-Base-White: #ffffff;
  --UI-DS-Base-Black: #000000;

  //  GRAY
  --UI-DS-Gray-25: #fcfcfd;
  --UI-DS-Gray-50: #f9fafb;
  --UI-DS-Gray-100: #f2f4f7;
  --UI-DS-Gray-200: #e4e7ec;
  --UI-DS-Gray-300: #d0d5dd;
  --UI-DS-Gray-400: #98a2b3;
  --UI-DS-Gray-500: #667085;
  --UI-DS-Gray-600: #475467;
  --UI-DS-Gray-700: #344054;
  --UI-DS-Gray-800: #1d2939;
  --UI-DS-Gray-900: #101828;

  // BRAND
  --UI-DS-Brand-25: #fafaff;
  --UI-DS-Brand-50: #f5f5ff;
  --UI-DS-Brand-100: #ebeaff;
  --UI-DS-Brand-200: #d9d9ff;
  --UI-DS-Brand-300: #bcbbfa;
  --UI-DS-Brand-400: #9290f5;
  --UI-DS-Brand-500: #706ef5;
  --UI-DS-Brand-600: #5e5ce5;
  --UI-DS-Brand-700: #3f3dd9;
  --UI-DS-Brand-800: #2e2c9e;
  --UI-DS-Brand-900: #24237d;

  // ERROR
  --UI-DS-Semantic-Error-25: #fffbfa;
  --UI-DS-Semantic-Error-50: #fef3f2;
  --UI-DS-Semantic-Error-100: #fee4e2;
  --UI-DS-Semantic-Error-200: #fecdca;
  --UI-DS-Semantic-Error-300: #fda29b;
  --UI-DS-Semantic-Error-400: #f97066;
  --UI-DS-Semantic-Error-500: #f04438;
  --UI-DS-Semantic-Error-600: #d92d20;
  --UI-DS-Semantic-Error-700: #b42318;
  --UI-DS-Semantic-Error-800: #912018;
  --UI-DS-Semantic-Error-900: #7a271a;

  // WARNING
  --UI-DS-Semantic-Warning-25: #fffcf5;
  --UI-DS-Semantic-Warning-50: #fffaeb;
  --UI-DS-Semantic-Warning-100: #fef0c7;
  --UI-DS-Semantic-Warning-200: #fedf89;
  --UI-DS-Semantic-Warning-300: #fec84b;
  --UI-DS-Semantic-Warning-400: #fdb022;
  --UI-DS-Semantic-Warning-500: #f79009;
  --UI-DS-Semantic-Warning-600: #dc6803;
  --UI-DS-Semantic-Warning-700: #b54708;
  --UI-DS-Semantic-Warning-800: #93370d;
  --UI-DS-Semantic-Warning-900: #7a2e0e;

  // SUCCESS
  --UI-DS-Semantic-Success-25: #f6fef9;
  --UI-DS-Semantic-Success-50: #ecfdf3;
  --UI-DS-Semantic-Success-100: #d1fadf;
  --UI-DS-Semantic-Success-200: #a6f4c5;
  --UI-DS-Semantic-Success-300: #6ce9a6;
  --UI-DS-Semantic-Success-400: #32d583;
  --UI-DS-Semantic-Success-500: #12b76a;
  --UI-DS-Semantic-Success-600: #039855;
  --UI-DS-Semantic-Success-700: #027a48;
  --UI-DS-Semantic-Success-800: #05603a;
  --UI-DS-Semantic-Success-900: #054f31;

  // SECONDARY
  // TEAL
  --UI-DS-Secondary-Teal-25: #f6fefc;
  --UI-DS-Secondary-Teal-50: #f0fdf9;
  --UI-DS-Secondary-Teal-100: #ccfbef;
  --UI-DS-Secondary-Teal-200: #99f6e0;
  --UI-DS-Secondary-Teal-300: #5fe9d0;
  --UI-DS-Secondary-Teal-400: #2ed3b7;
  --UI-DS-Secondary-Teal-500: #15b79e;
  --UI-DS-Secondary-Teal-600: #0e9384;
  --UI-DS-Secondary-Teal-700: #107569;
  --UI-DS-Secondary-Teal-800: #125d56;
  --UI-DS-Secondary-Teal-900: #134e48;

  // ROSE
  --UI-DS-Secondary-Rose-25: #fff5f6;
  --UI-DS-Secondary-Rose-50: #fff1f3;
  --UI-DS-Secondary-Rose-100: #ffe4e8;
  --UI-DS-Secondary-Rose-200: #fecdd6;
  --UI-DS-Secondary-Rose-300: #fea3b4;
  --UI-DS-Secondary-Rose-400: #fd6f8e;
  --UI-DS-Secondary-Rose-500: #f63d68;
  --UI-DS-Secondary-Rose-600: #e31b54;
  --UI-DS-Secondary-Rose-700: #c01048;
  --UI-DS-Secondary-Rose-800: #a11043;
  --UI-DS-Secondary-Rose-900: #89123e;
}
